import HomeIcon from '@mui/icons-material/Home';
import React from 'react';
import { getBaseTspmCoreURL } from '../utils/getBaseUrls';
import { getRequest } from '../services/axiosClient';
import {format} from 'date-fns';

//  top navigation list
export const navLinks = [
    {
        id: '/',
        name: <HomeIcon sx={{ fontSize: 28 }} />,
    },
    {
        id: 'dashboard',
        name: 'Dashboard',
    },
    {
        id: 'updateMyResources',
        name: 'My Resources',
    },
    {
        id: 'initiative',
        name: 'Initiatives',
    },
    {
        id: 'admin',
        name: 'Admin',
    },
];

// dashboard page tableau links
export const tableauLinks = [
    {
        id: 'financialRecovery',
        name: 'Financial Recovery',
        url: 'https://prodbitabcrp.jnj.com/t/IT-Insights/views/FinancialRecovery/FinancialRecovery-AD&:jsdebug=n',
        hideTabs: false,
        filter: null,
        tableau:true
    },
    {
        id: 'workAssignments',
        name: 'Work Assignments',
        url: 'https://prodbitabcrp.jnj.com/t/IT-Insights/views/ResourceManagementRecoverability/VPDashboard&:jsdebug=n?',
        hideTabs: true,
        filter: 'Recovery',
        tableau:true
    },
    {
        id: 'resourceAllocations',
        name: 'Resource Allocations',
        url: 'https://prodbitabcrp.jnj.com/t/IT-Insights/views/ResourceAllocation/TSLTdashboard&:jsdebug=n',
        hideTabs: true,
        filter: null,
        tableau:true
    },
    {
        id: 'initiativeAllocations',
        name: 'Initiative Allocations',
        url: 'https://prodbitabcrp.jnj.com/t/IT-Insights/views/ResourceManagementRecoverability/VPDashboard&:jsdebug=n',
        hideTabs: true,
        filter: 'Allocation',
        tableau:true
    },
    {
        id: 'availableResourceList', 
        name: 'Available Resources List', 
        title:'Available Resources List',
        tableau:false, 
        url:'/api/datasrcs/1/folder/rmr/customQuery/available_resources_list_current_plus_3'
    },
    {
        id: 'allResourceMonthlyRecovery', 
        name: 'All Resource Monthly Recovery',
        title:'All Resource Monthly Recovery',
        tableau:false, 
        url:'/api/datasrcs/1/folder/rmr/customQuery/all_resource_monthly_recovery'
    },
    {
        id: 'allResourceAttributes', 
        title: 'All Resource Attributes',
        name:'Organization Resource Data Profiles',
        tableau:false, 
        url:'/api/datasrcs/1/folder/rmr/customQuery/all_resource_attributes'
    },
    {
        id: 'initiativeByProjects', 
        name: 'Initiative By Projects',
        title: 'Initiative By Projects',
        tableau:false, 
        url:'/api/datasrcs/1/folder/rmr/customQuery/initiative_by_projects'
    },
    {
        id: 'tsEmployeeTransfers', 
        name: 'TS to Non-TS Employee Transfers',
        title: 'TS to Non-TS Employee Transfers',
        tableau:false, 
        url:'/api/datasrcs/1/schemas/rmr/tables/rmr_ts_employee_movement/records?sort-start_date_outside_ts=DESC'
    }
];

export function getTableauId(idTableau) {
    return tableauLinks.find(item => item.id === idTableau);
}

// get next 12 month
export function getMonthNames(dateVal) {

    let d = dateVal ? new Date(dateVal) : new Date();
    d.setDate(1);
    let color  = '#387bff';
    // Set to 1st of month
    // d.setDate(1);
    let result = [];
    // Get 12 month names, starting with current month
    const maxNum = 13;
    const tenNum= 10;
    const eleNum = 11;
    const tweNum =12;
    for (let i = 0; i < maxNum; i++) {

        const month = d.toLocaleString('default', { month: 'numeric' }) < tenNum ? '0'+d.toLocaleString('default', { month: 'numeric' }) :d.toLocaleString('default', { month: 'numeric' });
        const date = d.toLocaleString('default', { year: 'numeric' }) + ' ' +  d.toLocaleString('default', { month: 'short' });
        const ymd = d.toLocaleString('default', { year: 'numeric' }) + '-' + month + '-01';
        result.push(
            {date:date, ymd:ymd, backgroundColor:color }
        );
        if (d.getMonth() === eleNum || i === tweNum) {
            result.push({date:d.toLocaleString('default', { year: 'numeric' }) + ' Target', target:'app_dev_recov_tgt_pct' + d.toLocaleString('default', { year: 'numeric' }), backgroundColor:color});
            result.push({date:d.toLocaleString('default', { year: 'numeric' }) + ' Average', average:'avg_pct'  + d.toLocaleString('default', { year: 'numeric' }),backgroundColor:color}
            );
            color = '#216afa !important';
        }
        d.setMonth(d.getMonth() + 1);
    }

    return result;
}


// get all dates between two dates
const month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export function dateRange(startDate, endDate) {
    
    let start      = startDate.split('-');
    let end        = endDate.split('-');
    let startYear  = parseInt(start[0]);
    let endYear    = parseInt(end[0]);
    let dates      = [];
    const tenNum= 10;
    const eleNum = 11;
    const tweNum =12;

    /* Getting the start year and end year, then looping through each year, and for each year, it is
    getting the start month and end month, then looping through each month, and for each month, it
    is pushing the month to an array. */
    for(let i = startYear; i <= endYear; i++) {
        let endMonth = i != endYear ? eleNum : parseInt(end[1]) - 1;
        let startMon = i === startYear ? parseInt(start[1])-1 : 0;
        for(let j = startMon; j <= endMonth; j = j > tweNum ? j % tweNum || eleNum : j+1) {
            let month = j+1;
            let displayMonth = month < tenNum ? '0'+month : month;

            dates.push({year:i,month: displayMonth, ymd:`${i}-${displayMonth}-01`, monthName:month_names_short[month-1]});
        }
    }
    return dates;
}

/**
 * It gets the current month, then loops 4 times, 
 * pushing the current month to an array, then
 * increments the month, and if the month is greater than 11, it resets it to 0.
 * @returns An array of the next four months.
 */
export function getFourMonth(){
    
    let startMonth = new Date().getMonth();
    const eleNum = 11;
    const fourNum = 4;
    const arr = [];
    for (let index = 0; index < fourNum ; index++) {
        arr.push(month_names_short[startMonth]);
        startMonth++;
        if(startMonth >eleNum){
            startMonth = 0;
        }
    }
    return arr;
}


/* Getting the difference between two dates. */
export function monthDiff(dateFrom, dateTo) {
    const tweNum =12;
    const start = new Date(dateFrom);
    const end = new Date(dateTo);
    return end.getMonth() - start.getMonth() + 
      (tweNum * (end.getFullYear() - start.getFullYear()));
}

/**
     * It takes a date string, converts it to a date object, then returns a string of the first day of
     * the month of the date object.
     * @returns A string in the format of YYYY-MM-DD
     */
export const getFirstDayOfDate = value => {
    const fifteen = 15;
    // if not value given we set default starting date given by package
    if(!value) return '1970-01-01';
    const newValue = value?.toString().substring(0, fifteen);
    const date  = format(new Date(newValue), 'yyyy-MM');
    return `${date}-01`;
};

/**
 * It sorts an array of objects by the date in the start_date property of each object.
 * @returns The array is being sorted by the start_date.
 */
export const sortEffectiveDates = arr =>{
    arr.sort(function(a,b){
        return new Date(a.start_date) - new Date(b.start_date);
    });
    return arr;
};

/**
 * It takes an array of objects, and for each object, it sets the end_date property to the start_date
 * property of the next object in the array.
 * @returns The array with the end_date property added to each object.
 */
export const setEndDates = arr =>{
    for (let index = 0; index < arr.length-1; index++) {
        arr[index].end_date  = arr[index+1].start_date;
    }
    if(arr.length > 0){
        const lastRecord= arr.length-1;
        arr[lastRecord].end_date = null;
    }
    return arr;
};

//debounce function
const defaultDelay = 250;
export function debounce(fn, delay = defaultDelay) {
    let timeout;
    
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            fn(...args);
        }, delay);
    };
}


export const isOverlap = (resourceStartDate, positionStartDate, positionEndDate) => {
    // Convert the dates to Date objects
    const pStartDate = new Date(positionStartDate);
    const pEndDate = new Date(positionEndDate);
    resourceStartDate = new Date(resourceStartDate);

    // Check if the positionEndDate is not null
    if (positionEndDate !== null) {
        // Check if the resource start date is between the position start and end dates
        if ((pStartDate < resourceStartDate) && (pEndDate > resourceStartDate)) {
            return true;
        }
        // Check if the resource start date is after the position start date
        if (pStartDate > resourceStartDate) {
            return true;
        }
    } else {
        // Return true if the position end date is null
        return true;
    }
    // Return false if there is no overlap
    return false;
};


export const checkResource = async selectedResource => {
    // Constants
    const BASE_TSPM_CORE_URL = getBaseTspmCoreURL();
    const API_URL = `${BASE_TSPM_CORE_URL}/api/datasrcs/1/schemas/rmr/tables/rmr_position_resource/records?display-value=true&resource_wwid=${selectedResource.wwid}`;
  
    try {
        const response = await getRequest(API_URL);
        const { data } = response;
  
        // Check if there are any records for the selected resource
        if (data.rowCount !== 0) {
        // Check each record for an overlap with the selected resource's start date
            for (const item of data.data) {
                // Destructure the item object
                const { start_date: positionStartDate, end_date: positionEndDate } = item;
  
                // Check if the resource is already assigned as an active resource on the position
                if (isOverlap(selectedResource.start_date, positionStartDate, positionEndDate)) {
                    return {errorStatus: false,
                        message: `This resource is already assigned as an Active resource on position ${item.position_id}.`,
                        popupStatus: true};
                }
            }
        }
  
        return {errorStatus: false, message: '', popupStatus: false,};
    } catch (err) {
        return {errorStatus: true,
            message: 'Error with getting position resource, please refresh page or try again later - ' + (err?.request.statusText || '!'),
            popupStatus: false,};
    }
};


export function handleDateChange(e, field, records, index) {
    // Get the first day of the date selected by the user
    const newDate = getFirstDayOfDate(e);
  
    // Get all the dates stored in the `tableName` table
    // You'll need to import or pass `getValues` as a prop to this function
   
  
    // Create an object to store the dates, so we can check if a date already exists
    const dateObj = {};
  
    // Iterate over the dates and add them to the `dateObj` object
    records.forEach((item, i) => {
        if (i !== index) {
            dateObj[item.start_date] = 1;
        }
    });
  
    // Check if the `newDate` already exists in the `dateObj` object
    if (dateObj[newDate] === 1) {
        // If the date already exists, show an error message
        alert(`You cannot have more than one record with the same start date ${newDate}`);
    } else {
        // If the date doesn't exist, update the `field` with the new date
        field.onChange(newDate);
    }
}