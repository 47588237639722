import React,{useEffect, useState} from 'react';
import { navLinks } from '../data';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { user, isAdmin } from '../../redux/features/userSlice';
import PersonIcon from '@mui/icons-material/Person';
import {getRequest} from '../../services/axiosClient';
import './nav.css';
import {getBaseTspmCoreURL} from '../../utils/getBaseUrls';

const Nav = () => {
    /* Destructuring the user object from the redux store.
    we will use wwid for link to user wwid updateResources page*/
    const {wwid, userName, profilePhoto} = useSelector(user);
    const adminStatus = useSelector(isAdmin);
    const [navArray, setNavArray] = useState(navLinks);
    const baseUrl = getBaseTspmCoreURL();
    const [sowUpdate, setSowUpdate] = useState({fetched:false, 
        data:{
            update_datetime_actual: '',
            update_datetime_pending: '',
        }});

    const getUpdateData = async ()=>{
        try {
            // This function is used to fetch data from an API endpoint and update a component's state
            // with the fetched data.
            const res = await getRequest(baseUrl + '/api/datasrcs/1/schemas/rmr/tables/rmr_last_update/records');
            // Check if the `name` property of the first data item 
            // is equal to 'bmo_core.dm_ordrmngmtsrvcs'
            if(res.data.data[0].name === 'bmo_core.dm_ordrmngmtsrvcs'){
                // Update the state with the fetched data
                setSowUpdate({
                    fetched: true,
                    data:{
                        update_datetime_actual: res.data.data[0].update_datetime,
                        update_datetime_pending: res.data.data[1].update_datetime,
                    }
                });
            } else {
                // If the `name` property of the first data item is not equal to 'bmo_core.dm_ordrmngmtsrvcs',
                // update the state with the fetched data in a different way
                setSowUpdate({
                    fetched: true,
                    data:{
                        update_datetime_actual: res.data.data[1].update_datetime,
                        update_datetime_pending: res.data.data[0].update_datetime,
                    }
                });
            }
            
        } catch (err) {
            /*
            setting errors
            */
            const msg = err.request?.statusText ? ` - ${err.request?.statusText}!` : '!';
            setSowUpdate({
                fetched: true,
                data:{
                    update_datetime_actual: 'Unable to get SOW update information' + msg,
                    update_datetime_pending: 'Unable to get SOW update information' + msg,
                }
            });
        }
    };
    useEffect(() => {
        getUpdateData();
        //removing admin link from navigation
        if(!adminStatus){
            const newArr = navLinks.filter(item=> item.id !== 'admin');
            setNavArray(newArr);
        }
    }, []);

    return (
        <div className="nav-container">
            <div className="nav-links-wrap">
                <img
                    src="/images/JJ_Logo_Small.png"
                    alt="Johnson and Johnson Logo"
                    className="jjt-logo"
                />
            </div>
            <div className="user-info">
                <nav className="nav-links">
                    {navArray.map(item => {
                        const navLink = (item.id === 'updateMyResources') ? `${item.id}/wwid/${wwid}` : item.id;
                        return (
                            <NavLink
                                to={navLink}
                                key={navLink}
                                className={({ isActive }) =>
                                    isActive ? 'navLink navLink-active' : 'navLink'
                                }
                            >
                                {item.name}
                            </NavLink>
                        );
                    })}
                </nav>
                <div className='nav-user'>
                    { profilePhoto
                        ? <img style={{ borderRadius: '50%', height: '32px'}} alt="Insights & Solutions Technology Services" src={`data:image/png;base64,${profilePhoto}`}/>
                        : <PersonIcon/>}
                    <span style={{height:'40px',display: 'flex', alignItems: 'center'}}>{userName}</span>
                </div>
                
            </div>
            {sowUpdate.fetched && <div className="rmr-last-update" data-testid='rmr-last-update'>
                {/* placeholder for update text */}
                Actual SOW Data Last Update: <span>{sowUpdate.data.update_datetime_actual}</span> <br />
                Pending SOW Data Last Update: <span>{sowUpdate.data.update_datetime_pending}</span>
            </div>}
        </div>
    );
};

export default Nav;
