import popupStyles from './customPopup.module.css';
import PropTypes from 'prop-types';
import React,{ useEffect, useRef, useState } from 'react';


//to use this popup pass onClose props, title, children
const CustomPopup = props => {
    // title prop and onClose is optional and if not given it will not render title section of popup
    // also it will not render close, if you want to render close(x) give title empty string

    const ref = useRef();
    let paddingSize = 40;
    const ninetyPercent = 0.9;
    // minWidth prop is optional if you want to render with min-width give desired size othervise it will fit-content
    const {minWidth, onClose} = props;
    const [maxHeight, setMaxHeight] = useState(window.innerHeight * ninetyPercent );
    const [winSize, setWinSize] = useState(window.innerHeight);

   
    /**
     * When the window is resized, set the window size to the window's inner height, and set the max
     * height to 90% of the window's inner height minus 40(this comes from padding).
     */
    
    const resizeEventListener = () => {
        setWinSize(window.innerHeight);
        setMaxHeight((window.innerHeight * ninetyPercent)-paddingSize);
    };


    
    useEffect(() => {
        // disabling body scroll
        document.body.style.overflow = 'hidden';
        //addding resize listener to track windows size
        window.addEventListener('resize', resizeEventListener);
        if(ref.current.clientHeight >= winSize){
            setMaxHeight((winSize * ninetyPercent)-paddingSize);
        }
        // cleanup
        return () => {
            document.body.style.overflow = ''; 
            window.removeEventListener('resize', resizeEventListener);
        };
    }, []);

    
    // handling close
    const closeHandler = e => {
        e.stopPropagation();
        e.preventDefault();
        onClose(false);
    };

   
    return (
        <div
            className={popupStyles.overlay}
            data-testid='custom-popup'
        >
            <div className={popupStyles.popup} style={{minWidth, maxHeight:maxHeight}} ref={ref}>
                {props.title &&  (<div className={popupStyles.title} >
                    <h2>{props.title}</h2>
                    {props.onClose && <span className={popupStyles.close} onClick={closeHandler} data-testid = 'close-custom-popup'>&times;
                    </span>}
                </div>)}
                <div className={popupStyles.content}>
                    {props.children}</div>
            </div>
        </div>
    );
};

CustomPopup.propTypes = {
    title: PropTypes.string,
    onClose: PropTypes.func,
    minWidth: PropTypes.string,
    children: PropTypes.any,
};
export default CustomPopup;